.card {
  margin-left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background: #232323;
  border: 1px solid #262729;
  box-shadow: 5px 10px 10px 2px rgba(32, 141, 97, 0.5);
  font-size: 21px;
  font-weight: 200 !important;
  cursor: pointer;

  -webkit-transition: background 1s, border-radius 1s;
    transition: background 1s, border-radius 1s;
  &.active {
    --borderWidth: 3px;
    background: #1D1F20;
    position: relative;
    border-radius: var(--borderWidth);
    box-shadow: 5px 10px 10px 2px rgba(131, 122, 122, 0.5);
  }

  &.active::after {
    transition: all 0.5s ease;

    content: '';
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }

  .company-logo {
    width: 70%;
    height: auto;
  }

}

.gradient-border {}

.gradient-border:after {}


@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}