.portfolio {
    padding-left: 1.5%;
    width: 100%;

    .portfolio-items {
        position: relative;
        top: -100px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 90px;
        padding-block: 60px;
        width: 100%;

        .portfolio-item {
            position: relative;

            &:hover {
                z-index: 2;
                border-radius: 2rem;
                background-color: rgba(0, 0, 0, 0.2);
            }

            &:hover .portfolio-item-image {
                opacity: 0.2;
            }

            &:hover .info {
                display: block;
            }

            .info {
                display: none;
                transition: all 0.5 ease-in-out;
                width: 90%;
                padding: 20px;
                top: 0;
                position: absolute;

                h4 {
                    margin-block-start: 0;
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                    color: #4e63a7;

                }

                .description {
                    font-size: 1rem;
                    color: #e4ebff;
                }
            }

            .portfolio-item-image {
                object-fit: cover;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 200px;

                img {

                    width: 200px;
                }
            }
        }


    }

    .item-info {
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 160px;
    }

}

@media screen and (max-width: 983px) {
    .portfolio-items{
        display: flex !important;
        flex-direction: column;
        margin-top:100px !important;
        .info{
            left:20px;
            width: 90%;
            top: 150px;
        }
    }
}