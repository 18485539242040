.main-page {
  padding-left: 5%;
  overflow: hidden;

}

.header {
  margin: 0;
  display: flex;
  width: fit-content;

  .word-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0.9rem;
    transition: all 0.2s ease-in-out;

    p {

      &:hover {
        color: #5fa3c7;
      }

      color: #4e63a7;
      transition: all 0.2s ease-in-out;
      cursor: text;

      &:first-child {
        margin-left: 0;
      }

      display: inline-flex;
      margin: 0;
      padding: 0;

      &:first-child {
        padding-left: 0.5rem;
      }
    }
  }
}

.header-outer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.header-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &::before {
    color: #879695;
    margin-top: 1em;
    top: -1rem;
    left: -0.3rem;
    opacity: 0.5;
    position: absolute;
    cursor: text;
    content: '<h1>';
  }

  &::after {
    color: #879695;
    bottom: 2rem;
    opacity: 0.5;
    right: -1.5rem;
    margin-top: -5rem;
    cursor: text;
    position: absolute;
    content: '</h1>';
  }
}

.content {
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: fit-content;

  .button-fill {
    display: flex;
    margin-top: 5rem;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    height: 5rem;
    width: 27rem;
    background: linear-gradient(to left, transparent 50%, #5fa3c7 50%) right;
    background-size: 200%;
    transition: 0.5s ease-out;

    &:hover {
      background-position: left;
      color: #e5dbd5;
    }
  }
}

small {
  flex-wrap: wrap;
  display: flex;
  position: relative;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  color: #879695;
  transition: 0.3s ease-out;
  margin-top: 1rem !important;

  p {
    margin: 0;
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  &::before {
    top: -1.5rem;
    opacity: 0.5;
    left: -1.5rem;
    position: absolute;
    content: '<p>';
  }

  &::after {
    right: -2rem;
    opacity: 0.5;
    bottom: -1.5rem;
    position: absolute;
    content: '</p>';
  }
}

@media screen and (min-width: 1281px) {
  .button-fill {
    font-size: 2.5rem;
  }

  .header {
    p {
      font-size: 3.5rem;
    }
  }
}

@media screen and (max-width: 1281px) {
  .button-fill {
    font-size: 2rem !important;
    width: 18rem !important;
    height: 4rem !important;
  }

  small {
    font-size: x-small;
    width: 80%;

  }

  .header {

    .word-wrapper {
      margin-right: 0.5rem;
    }

    p {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 500px) {

  .header {
    position: relative;
    left: 0%;
  }
}

@media screen and (max-width: 961px) {
  small {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .button-fill {
    font-size: 1.5rem !important;
    width: 14rem !important;
    height: 3rem !important;
  }

  small {
    font-size: xx-small;
  }

  .header {
    .word-wrapper {
      margin-right: 0.2rem;

      p {
        font-size: 2.0rem;
      }
    }


  }
}

@media screen and (max-width: 481px) {
  .header {
    flex-direction: column;
    p {
      font-size: 0.7rem;
    }

  }
}

@media screen and (max-width: 320px) {
  .header {
    line-height: 0;

    .word-wrapper {
      margin-right: 0.2rem;
    }

    p {
      font-size: 0.5rem;
    }
  }
}


.no-shrink {
  flex-shrink: 0;
}