.sidebar-nav {
  width: 20%;
  flex-shrink: 0;
  height: 100vh;
  background-color: black;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #181818;
  transition: all 0.2s ease-in-out;
  justify-content: start;
}

@media screen and (max-width: 1025px) {
  .sidebar-nav {
    width: 80px;
  }
}

.logo-wrapper {
  top: 3rem;
  transition: all 0.2s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

@media screen and (max-height: 600px) {
  .logo-wrapper {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .sidebar-link.scale-up-center {
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both !important;
  }
}

.sidebar-items-wrapper {
  -webkit-transition: all 1s;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 160px;
  justify-self: center;
  width:100%;
  list-style-type: none;

  .sidebar-link {
    &.active {
      background-color: #1f1f1b;
    }

    transition: all 0.5s ease-in-out;
    display: flex;
    width: 100%;
    height: 3.75rem;
    line-height: 38.11px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #e5dbd5;
    border-top: 0.75px solid #2f2f2f;

    &:first-child {
      border-top: none;
    }

    &:hover {
      background-color: #1f1f1b;
    }

    .label {
      color: #4e63a7;
    }
  }
}

.sidebar-footer {
  position: absolute;
  display: flex;
  bottom: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  padding: 0 1rem;
  border-top: 0.75px solid #2f2f2f;
  transition: all 0.5s ease-in-out;

  .socials {
    height: 100%;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    a {
      display: flex;
      font-size: 1.1rem;
      text-decoration: none;
      align-items: center;



      &:hover {
        p {
          color: #5fa3c7;
          font-size: 1.3rem;
        }
      }

      p {
        margin: 0;
        padding: 0;
        color: #e5dbd5;
        font-size: 1rem;
        margin-left: 0.75em;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 1281px) {
  .logo {
    width: 256px;
    height: 256px;
  }

  .socials {
    p {
      font-size: 1rem !important;
    }
  }
}

@media screen and (max-width: 1025px) {
  .socials {
    p {
      font-size: 0.85rem !important;
    }
  }

  .socials {
    padding-left: 0rem;
    width: 100%;
    align-items: center;

  }

  .sidebar-nav {
    justify-content: start;

  }
}

@media screen and (max-width: 768px) {


  .logo {
    width: 192px;
    height: 192px;
  }
}

@media screen and (max-height: 320px) {
  .logo {
    width: 128px;
    height: 128px;
  }
}

.no-display {
  display: none;
}

.slide-out-left {
  -webkit-animation: slide-out-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
  animation: slide-out-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both !important;
}